import React from "react";
import useMenuData, { Choice } from "./menuData"; // Import the JSON data
import "./Menu.css"; // Custom CSS file for styling
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const MenuPage = ({ isAdmin = false }: { isAdmin?: boolean }) => {
  const { menuData } = useMenuData();
  const nav = useNavigate();
  return (
    <div className="menu">
      <h1 className="menu-title">Our Menu</h1>

      {/* Map over each category */}
      {menuData &&
        Object.keys(menuData).map((category) => (
          <Section key={category} title={capitalize(category)}>
            {menuData[
              category as
                | "appetizers"
                | "entrees"
                | "curry Dishes"
                | "side Dishes"
                | "specialty Dishes"
            ].map((item, index) => (
              <MenuItem
                onEditClick={() => nav(`/admin/menu/${item.id}`)} // Navigate to edit page
                key={index}
                name={item.name}
                description={item.description}
                price={item.price}
                choices={item.choices}
                isAdmin={isAdmin} // Pass isAdmin prop to MenuItem
                images={item.imageUrl ? [item.imageUrl] : []} // Handle images
                // if available
              />
            ))}
          </Section>
        ))}
    </div>
  );
};

const Section = ({
  title,
  children,
}: {
  title: string;
  children: JSX.Element | JSX.Element[];
}) => (
  <div className="menu-section">
    <h2 className="menu-section-title">
      {title} {title === "Specialty Dishes" ? " (Order ahead)" : ""}
    </h2>
    <div className="menu-items">{children}</div>
  </div>
);

const MenuItem = ({
  name,
  description,
  price,
  choices,
  images,
  onEditClick,
  isAdmin = false, // Optional prop to indicate if the item is an ad
}: {
  name: string;
  description: string;
  price: number;
  choices: Choice[] | undefined;
  images?: string[];
  isAdmin?: boolean; // Optional prop to indicate if the item is an ad
  onEditClick?: () => void; // Optional prop for edit functionality
}) => (
  <div className="menu-item">
    {isAdmin && (
      <Button
        onClick={onEditClick}
        variant="outlined"
        size="small"
        color="secondary"
        sx={{ mb: 1 }}
      >
        Edit
      </Button>
    )}
    <h3 className="menu-item-name">
      {name} <span className="menu-item-price">${price}</span>
    </h3>
    <p className="menu-item-description">{description}</p>
    <Box sx={{ display: "flex", justifyContent: "center", mb: 0 }}>
      {images && images.length > 0 && (
        <img
          src={images[0]} // Display the first image if available
          alt={name}
          className="menu-item-image"
        />
      )}
    </Box>
    {choices && (
      <ul>
        {choices.map((c) => (
          <li key={c.name}>
            {/* <div>{c.name}</div> */}
            <p className="menu-item-description">
              <span style={{ fontWeight: "bold", width: "100%" }}>
                {c.name}
              </span>
              {"  +   "}
              <span>${c.priceAdjustment}</span>
            </p>
          </li>
        ))}
      </ul>
    )}
  </div>
);

// Helper function to capitalize section titles
const capitalize = (text: string) =>
  text.charAt(0).toUpperCase() + text.slice(1);

export default MenuPage;

import { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { db } from "../../utils";
import { set } from "date-fns";

export interface Choice {
  name: string;
  priceAdjustment: string;
}
export interface MenuItem {
  id: string; // Add ID field for document reference
  category: string; // e.g., "Appetizers"
  createdOn: Date; // e.g., "March 18, 2025 at 6:50:02 PM UTC-4"
  description: string; // e.g., "Lightly fried sweet plantains, golden and crispy"
  imageUrl: string; // e.g., ""
  name: string; // e.g., "Fried Plantains"
  price: number; // e.g., "5.99"
  choices?: Choice[]; // e.g., [{ name: "Small", price: "5.99" }, { name: "Large", price: "8.99" }]
}

const mealCategories = [
  "Appetizers",
  "Entrees",
  "Curry Dishes",
  "Side Dishes",
  "Specialty Dishes",
];

export interface MenuData {
  [key: string]: MenuItem[];
}

const useMenuData = () => {
  const [menuData, setMenuData] = useState<MenuData | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const menuCollection = collection(db, "menu");
    const queryMenuCollection = query(
      menuCollection,
      orderBy("createdOn", "asc")
    );
    const unsubscribe = onSnapshot(queryMenuCollection, (snapshot) => {
      const menuList = snapshot.docs.map(
        (doc) =>
          ({
            id: doc.id,
            ...doc.data(),
          } as MenuItem)
      );
      const categorizedMenu: MenuData = mealCategories.reduce(
        (acc, category) => {
          acc[category] = menuList.filter((item) => item.category === category);
          return acc;
        },
        {} as MenuData
      );
      setMenuData(categorizedMenu);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return { menuData, loading };
};

export default useMenuData;

import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  CircularProgress,
  InputAdornment,
  Select,
  InputLabel,
  MenuItem,
  IconButton,
  Box,
} from "@mui/material";
import { db, getMealById, storage } from "../utils";
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom";
import { Add, Delete } from "@mui/icons-material";
import { Choice } from "./MenuPage/menuData";

const mealCategories = [
  "Appetizers",
  "Entrees",
  "Curry Dishes",
  "Side Dishes",
  "Specialty Dishes",
];
const AddMealPage: React.FC = () => {
  const nav = useNavigate();
  const { id } = useParams();

  const [meal, setMeal] = useState({
    name: "",
    description: "",
    image: null as File | null,
    price: 0,
    category: mealCategories[0], // Default category
    choices: [] as Choice[],
  });
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [busy, setBusy] = useState(false);
  const [busy2, setBusy2] = useState(false);

  const [error, setError] = useState<string | null>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "image") {
      const file = e.target.files ? e.target.files[0] : null;
      setMeal({ ...meal, image: file });
      if (file) {
        setPreviewUrl(URL.createObjectURL(file));
      } else {
        setPreviewUrl(null);
      }
    } else {
      setMeal({ ...meal, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      // Upload image to Firebase Storage
      setBusy(true);
      let downloadURL = "";
      if (id && previewUrl) downloadURL = previewUrl;
      if (meal.image) {
        const storageRef = ref(storage, `meals/${meal.image.name}`);
        await uploadBytes(storageRef, meal.image);
        downloadURL = await getDownloadURL(storageRef);
        setImageUrl(downloadURL);
      }
      const data = {
        name: meal.name,
        description: meal.description,
        imageUrl: downloadURL,
        price: Number(meal.price),
        category: meal.category,
        choices: meal.choices.map((choice) => ({
          name: choice.name,
          priceAdjustment: Number(choice.priceAdjustment),
        })),
      };
      // Save meal details to Firestore
      if (!id)
        await addDoc(collection(db, "menu"), {
          ...data,
          ...{ createdOn: new Date() },
        });
      else await updateDoc(doc(db, "menu", id), data);

      setMeal({
        name: "",
        description: "",
        image: null,
        price: 0,
        category: mealCategories[0], // Reset to default category
        choices: [],
      });
      setPreviewUrl(null);
      setError(null);
      setBusy(false);
      nav("/admin");
    } catch (err) {
      setBusy(false);
      setError("Error saving meal");
    }
  };

  const deleteMeal = async () => {
    setBusy2(true);
    if (id) await deleteDoc(doc(db, "menu", id));
    setBusy2(false);
    nav("/admin", { replace: true });
  };
  useEffect(() => {
    if (id) {
      getMealById(id).then((x) => {
        if (x) {
          const { name, imageUrl, description, price, choices } = x;
          setMeal({
            name,
            description,
            price,
            image: null,
            category: mealCategories[0], // Default category
            choices: choices || [],
          });
          if (imageUrl) setPreviewUrl(imageUrl);
        }
      });
    }
  }, [id]);
  const handleChoiceChange = (
    index: number,
    field: "name" | "priceAdjustment",
    value: string | number
  ) => {
    const updatedChoices = [...meal.choices];
    updatedChoices[index] = {
      ...updatedChoices[index],
      [field]: field === "priceAdjustment" ? Number(value) : value,
    };
    setMeal({ ...meal, choices: updatedChoices });
  };

  const addChoice = () => {
    setMeal({
      ...meal,
      choices: [...meal.choices, { name: "", priceAdjustment: "0" }],
    });
  };

  const removeChoice = (index: number) => {
    const updatedChoices = meal.choices.filter((_, i) => i !== index);
    setMeal({ ...meal, choices: updatedChoices });
  };
  return (
    <Container
      sx={{
        p: 2,
        width: { md: "50%", xs: "100%" },
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="h4" gutterBottom>
        {id ? "Edit" : "Add"} Meal
      </Typography>
      <form onSubmit={handleSubmit}>
        <InputLabel id="category-label">Meal Category</InputLabel>
        <Select
          labelId="category-label"
          id="category-label"
          value={meal.category}
          label="Meal Category"
          onChange={(x) => setMeal({ ...meal, category: x.target.value })}
        >
          {mealCategories.map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </Select>
        <TextField
          name="name"
          label="Meal Name"
          fullWidth
          margin="normal"
          value={meal.name}
          onChange={handleChange}
        />
        <TextField
          name="description"
          label="Description"
          fullWidth
          margin="normal"
          multiline
          rows={4}
          value={meal.description}
          onChange={handleChange}
        />
        <TextField
          name="price"
          label=" Base Price"
          fullWidth
          margin="normal"
          value={meal.price}
          onChange={handleChange}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          type="number"
        />
        <Typography variant="h6" gutterBottom>
          Choices
        </Typography>
        <Box>
          {meal.choices.map((choice, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <TextField
                label="Choice Name"
                value={choice.name}
                onChange={(e) =>
                  handleChoiceChange(index, "name", e.target.value)
                }
                style={{ marginRight: "10px", flex: 1 }}
              />
              <TextField
                label="Additional Cost"
                type="number"
                value={choice.priceAdjustment}
                onChange={(e) =>
                  handleChoiceChange(index, "priceAdjustment", e.target.value)
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                style={{ marginRight: "10px", width: "150px" }}
              />
              <IconButton onClick={() => removeChoice(index)} color="error">
                <Delete />
              </IconButton>
            </div>
          ))}
          <Button
            variant="outlined"
            color="secondary"
            onClick={addChoice}
            startIcon={<Add />}
            style={{ marginBottom: "20px" }}
          >
            Add Choice
          </Button>
        </Box>
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="meal-image"
          type="file"
          name="image"
          onChange={handleChange}
        />
        <label htmlFor="meal-image">
          <Button
            variant="outlined"
            color="secondary"
            component="span"
            sx={{ mr: 2 }}
            size="large"
          >
            {previewUrl ? "Change" : "Add"} Meal Photo
          </Button>
        </label>
        {previewUrl && (
          <div style={{ marginTop: "10px" }}>
            {/* <Typography variant="h6">Image Preview:</Typography> */}
            <img
              src={previewUrl}
              alt="Preview"
              style={{ width: "400px", maxHeight: "400px", objectFit: "cover" }}
            />
          </div>
        )}
        {error && <Typography color="error">{error}</Typography>}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={busy || Number(meal.price) === 0 || !meal.name}
          size="large"
        >
          Save {busy && <CircularProgress color="inherit" sx={{ ml: 1 }} />}
        </Button>
        {id && (
          <Button
            variant="contained"
            color="error"
            disabled={busy2}
            size="large"
            sx={{ ml: 2 }}
            onClick={deleteMeal}
          >
            Delete
            {busy2 && <CircularProgress color="inherit" sx={{ ml: 1 }} />}
          </Button>
        )}
      </form>
    </Container>
  );
};

export default AddMealPage;
